
  import { Component } from 'vue-property-decorator'
  import StaffInspections from './Inspections.vue'
  import StaffLeads from './Leads.vue'
  import StaffSalesPurchases from './SalesPurchases.vue'
  import StaffDashboard from './StaffDashboard'
  import StaffStock from './Stock.vue'
  import StaffTeam from './Team.vue'

@Component({
  components: {
    StaffLeads,
    StaffSalesPurchases,
    StaffInspections,
    StaffTeam,
    StaffStock,
  },
})
  export default class StaffAll extends StaffDashboard {
    // Methods
    beforeCreate () {
      this.getData = async () => {
        await this.getGeneralResults()
      }
    }

  // Getters

  // Watchers
  }
