var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"mt-6",attrs:{"fluid":""}},[(_vm.showGeneralResults)?_c('v-row',[_c('v-col',[_c('general-results',{attrs:{"chart-data":_vm.chartData,"display-chart":{
          scheduledInspections: false,
          purchases: true,
          approvedCredits: false,
          signedCredits: true,
          reserves: true,
          sales: true,
        }}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{attrs:{"title":"Compras por ejecutivo"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.currentMonthFilters.stockCreatedDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: currentMonthFilters.stockCreatedDate})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.purchases,"records-settings":_vm.chartData.settings.purchase,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixPurchaseFields,"path-to-category":['executive'],"path-to-category-title":['fullName']}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{attrs:{"title":"Ventas por ejecutivo"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'sales', queryMethod: _vm.getSales, queryFilter: _vm.currentMonthFilters.startEndDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'sales', queryMethod: getSales, queryFilter: currentMonthFilters.startEndDate})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.sales,"records-settings":_vm.chartData.settings.sale,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixSaleFields,"path-to-category":['executive'],"path-to-category-title":['name']}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{attrs:{"title":"Créditos por ejecutivo"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'signedCredits', queryMethod: _vm.getLoanEvaluationsAggregates, queryFilter: _vm.currentMonthFilters.signedCredits})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'signedCredits', queryMethod: getLoanEvaluationsAggregates, queryFilter: currentMonthFilters.signedCredits})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.signedCredits,"records-settings":_vm.chartData.settings.loanEvaluations,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixCreditFields,"path-to-category":['executive'],"path-to-category-title":['fullName']}})]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }