var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"mt-6",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('general-results',{attrs:{"chart-data":_vm.chartData,"display-chart":{
          scheduledInspections: false,
          purchases: true,
          approvedCredits: false,
          signedCredits: true,
          reserves: true,
          sales: true,
        }}})],1)],1),_c('v-row',{staticClass:"mt-n6"},[_c('staff-sales-purchases',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
        if(!isIntersecting) return
        _vm.submitQueries([
          {category: 'currentMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.specificCurrentMonthFilters.stockCreatedDate},
          {category: 'currentMonth', field: 'sales', queryMethod: _vm.getSales, queryFilter: _vm.specificCurrentMonthFilters.startEndDate},
          {category: 'currentMonth', field: 'signedCredits', queryMethod: _vm.getLoanEvaluationsAggregates, queryFilter: _vm.specificCurrentMonthFilters.signedCredits},
        ], true)
      }),expression:"(entries, observer, isIntersecting) => {\n        if(!isIntersecting) return\n        submitQueries([\n          {category: 'currentMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: specificCurrentMonthFilters.stockCreatedDate},\n          {category: 'currentMonth', field: 'sales', queryMethod: getSales, queryFilter: specificCurrentMonthFilters.startEndDate},\n          {category: 'currentMonth', field: 'signedCredits', queryMethod: getLoanEvaluationsAggregates, queryFilter: specificCurrentMonthFilters.signedCredits},\n        ], true)\n      }"}],attrs:{"show-general-results":true}})],1),_c('v-row',{staticClass:"mt-n6"},[_c('staff-stock')],1),_c('v-row',{staticClass:"mt-n6"},[_c('staff-team',{attrs:{"show-general-results":false}})],1),_c('v-row',{staticClass:"mt-n6"},[_c('staff-leads')],1),_c('v-row',{staticClass:"mt-n6"},[_c('staff-inspections')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }