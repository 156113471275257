
  import { deepCopy } from '@/utils/general'
  import dayjs from 'dayjs'
  import { Component, Prop } from 'vue-property-decorator'
  import GeneralResults from '../common/GeneralResults.vue'
  import StaffDashboard from './StaffDashboard'

@Component({
  components: {
    GeneralResults,
  },
})
  export default class StaffTeam extends StaffDashboard {
  @Prop({ default: true, type: Boolean }) showGeneralResults

  // Methods
  beforeCreate () {
    this.getData = async () => {
      await this.getGeneralResults()
    }
  }

  normaliceReserves (records) {
    return records.map(record => {
      const { executive, executiveName } = record
      record.executive = {
        id: executive,
        name: executiveName,
      }
      return record
    })
  }

  fixPurchaseFields (records) {
    const corrected = deepCopy(records)
    for (let i = 0; i < corrected.length; i++) {
      const { auto, executive } = corrected[i]
      const carFullName = `${auto.versionYear.version.model.brand.name} ${auto.versionYear.version.model.name}`
      const executiveFullName = `${executive.person.firstName.trim()} ${executive.person.surname.trim()}`
      corrected[i].stockCreated = dayjs(corrected[i].stockCreated).format('DD/MM/YY - HH:mm')
      corrected[i].executive.fullName = executiveFullName
      corrected[i] = {
        ...corrected[i],
        carFullName,
        executiveFullName,
      }
    }

    return corrected
  }

  fixCreditFields (records) {
    const corrected = deepCopy(records)
    for (let i = 0; i < corrected.length; i++) {
      const { auto, executive } = corrected[i]
      const carFullName = `${auto.versionYear.version.model.brand.name} ${auto.versionYear.version.model.name}`
      const executiveFullName = `${executive.person.firstName.trim()} ${executive.person.surname.trim()}`
      corrected[i].signedDate = dayjs(corrected[i].signedDate).format('DD/MM/YY - HH:mm')
      corrected[i].executive.fullName = executiveFullName
      corrected[i] = {
        ...corrected[i],
        carFullName,
        executiveFullName,
      }
    }

    return corrected
  }

  fixSaleFields (records) {
    const corrected = deepCopy(records)
    for (let i = 0; i < corrected.length; i++) {
      const { autoDescription, year, executive, executiveName } = corrected[i]
      const carFullName = `${autoDescription} ${year}`
      corrected[i].soldDate = dayjs(corrected[i].soldDate).format('DD/MM/YY - HH:mm')
      corrected[i].executive = {
        id: executive,
        name: executiveName,
      }
      corrected[i] = {
        ...corrected[i],
        carFullName,
      }
    }

    return corrected
  }

  // Getters

  // Watchers
  }
